@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,500;1,500&display=swap');

// Color system

// gray
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

// brand
$brand-100: #FCE3CC;
$brand-200: #F8C799;
$brand-300: #F5AA66;
$brand-400: #F18E33;
$brand-500: #fd7e14;
$brand-600: #BE5B00;
$brand-700: #8F4400;
$brand-800: #5F2E00;
$brand-900: #301700;

$tertiary-color: $gray-500;
$tertiary-color-bg: $gray-100;

// theme
$brand:      $brand-500;
$primary:    $gray-900;
$info:       $brand;
$success:    #198754;

$body-secondary-color: $gray-600;
$body-secondary-bg: $gray-200;

// Options
$enable-shadows:              true;

$component-active-color:      $white;
$component-active-bg:         #e35102;

//focus-ring-variables
$focus-ring-opacity:    0;

// Typography
$font-size-base:              1rem;

// display-headings
$display-font-family: 'IBM Plex Sans', sans-serif;
$display-font-weight: 600;

$display-font-sizes: (
  1: 4.5rem,
  2: 3rem,
  3: 2rem,
  4: 1.5rem,
  5: 1rem,
  6: 0.875rem
);

//Headers
$h1-font-size:                $font-size-base * 2;
$h2-font-size:                $font-size-base * 1.5;
$h3-font-size:                $font-size-base * 1.25;
$h4-font-size:                $font-size-base;


// Buttons + Forms
$input-btn-padding-y:         .75rem;
$input-btn-padding-x:         1rem;
$input-btn-border-width:      2px;
$input-focus-border-color:    #EE7200;

// Inputs
$form-label-font-weight:                600;

// Buttons
//$btn-font-family:             'IBM Plex Sans', sans-serif;
$btn-font-weight:             600;

// Navs
$nav-pills-link-active-color:       $brand-800;
$nav-pills-link-active-bg:          $brand-100;

$body-bg:                   $gray-100;

$card-bg: $white;
$input-bg: $white;

$bg-secondary: $gray-200;
$bg-brand-secondary: $brand-100;
$bg-brand-tertiary: tint-color($brand-100, 80%);

// Modal
$modal-header-border-width: 0;


$table-bg: $white;

$prefix: 'bs-';
// Accordion
$accordion-padding-y:                     1rem;
$accordion-padding-x:                     1rem;
$accordion-color:                         $gray-900;
$accordion-bg:                            $white;
$accordion-border-width:                  2;
$accordion-border-color:                  $primary;
$accordion-border-radius:                 0.5rem;
$accordion-inner-border-radius:           0.5rem;

$accordion-body-padding-y:                $accordion-padding-y;
$accordion-body-padding-x:                $accordion-padding-x;

$accordion-button-padding-y:              $accordion-padding-y;
$accordion-button-padding-x:              $accordion-padding-x;
$accordion-button-color:                  $gray-900;
$accordion-button-bg:                     $white;
//$accordion-transition:                    $btn-transition, border-radius .15s ease;
$accordion-button-active-bg:              $white;
$accordion-button-active-color:           var(--#{$prefix}primary-text-emphasis);

$accordion-button-focus-border-color:     $input-focus-border-color;
//$accordion-button-focus-box-shadow:       $btn-focus-box-shadow;

$accordion-icon-width:                    1.25rem;
//$accordion-icon-color:                    $body-color;
//$accordion-icon-active-color:             $primary-text-emphasis;
$accordion-icon-transition:               transform .2s ease-in-out;
$accordion-icon-transform:                rotate(-180deg);

//$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
//$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");


$spinner-border-width-sm: 0.1em;

$offcanvas-horizontal-width: 350px;

$progress-bg: $brand-100;
$progress-bar-bg: $brand-500;
$progress-height: 0.5rem;
$progress-box-shadow: 0;
$progress-border-radius: 0.5rem;

$box-shadow-inset: unset;

$input-placeholder-color: $tertiary-color;
