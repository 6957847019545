@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import "./style";
@import 'bootstrap/scss/variables';

$spacers: map-merge(
  $spacers,
  (
    15: .375rem,
    45: 2rem,
  )
);

// Bootstrap
@import "bootstrap/scss/mixins/banner";
@include bsBanner("");
//@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/utilities";

// Layout & components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
//@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
//@import "bootstrap/scss/breadcrumb";
//@import "bootstrap/scss/pagination";
//@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
//@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
//@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
//@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";
@import "bootstrap/scss/progress";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";
// scss-docs-end import-stack

//////////////////////////////////////////////////

.progress-bar {
  border-radius: 0.25rem;
}

h3 {
  line-height: 1.4rem;
}

.module-content {
  max-width: 43.75rem;
  gap: var(--spacer-4, 1.5rem);
  margin: var(--spacer-5, 3rem) var(--spacer-3, 1rem) var(--spacer-0, 0rem) var(--spacer-3, 1rem);

  @include media-breakpoint-up(md) {
    margin-left: 4.5rem;
    margin-top: 6rem;
  }

  .lesson-text {
    > p, > ul > li, > ol > li {
      &:hover {
        cursor: pointer;
        position: relative;
        z-index: 0;

        &:after {
          content: '';
          position: absolute;
          z-index: -1;
          left: -0.25rem;
          top: -0.25rem;
          width: calc(100% + 0.5rem);
          height: calc(100% + 0.5rem);
          border-radius: 0.25rem;
          background: $gray-100;
        }
      }
    }
  }

  p[data-paragraph-extension-form], li[data-paragraph-extension-form] {
    position: relative;
    z-index: 0;
    margin-bottom: 0.5rem;

    &:hover {
      cursor: unset;
      text-decoration: none;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      left: -0.25rem;
      top: -0.25rem;
      width: calc(100% + 0.5rem);
      height: calc(100% + 0.5rem);
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), $brand-100!important;
      color: $brand-800;
      border-radius: 0.25rem;
      opacity: 0.75;
      transition: background-color 0.3s ease
    }

  }

  li[data-paragraph-extension-form] {
    &:after {
      left: -2.25rem!important;
      width: calc(100% + 2.5rem)!important;
    }
  }

  .paragraph-extend-form {
    position: relative;
    left: -0.25rem;
    width: calc(100% + 0.5rem);
    display: block;
    padding: 0;

    .dropdown-menu {
      width: 100%;
      button {
        transition: none;
      }
    }
  }

  ol .paragraph-extend-form, ul .paragraph-extend-form {
    left: -2.25rem;
    width: calc(100% + 2.5rem);
  }

  .illustration-card-wrapper {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: calc(calc(var(--bs-gutter-x) * -.5) - 1rem);
    margin-left: calc(calc(var(--bs-gutter-x) * -.5) - 1rem);
    padding: var(--spacer-4, 1.5rem);
    width: auto;
    display: block;
    border-radius: 0.5rem;
    background-color: $gray-100;

    @media (min-width: 768px) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .illustration-card{
    border: none;
    box-shadow: none;
    background: none;

    .swiper {
      height: auto;
      width: 100%;

    }

    .swiper-button-prev, .swiper-button-next {
      color: $gray-500;

      &:after {
        font-size: 1.75rem;
      }
    }

    .source-caption {
      border-radius: 0.5rem;
      background: var(--gray-color-variables-gray-100, #F8F9FA);
      position: fixed;
      bottom: -0.5rem;
      right: 0;
      background-color: $gray-100;
      padding: 0 3px 3px;

      a {
        color: $gray-600;
      }
    }

    .swiper-pagination{
      position: relative;
      bottom: -0.5rem;
    }

    .swiper-pagination-bullet {
      border: 1px solid $primary;
      background-color: unset;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background-color: $primary;
    }

    .swiper-wrapper {
      align-items: center;
    }

    img {
      max-height: 264px;
    }

    @media (min-width: 768px) {
    }
  }

  .module-content {
    padding: var(--spacer-1, 0.25rem) var(--spacer-0, 0rem) var(--spacer-1, 0.25rem) var(--spacer-3, 1rem);
    border-left: 2px solid $brand-200;
    margin: var(--spacer-2, 0.5rem) var(--spacer-0, 0rem) var(--spacer-2, 0.5rem) var(--spacer-2, 0.5rem);

    @include media-breakpoint-up(md) {
      padding: var(--spacer-1, 0.25rem) var(--spacer-0, 0rem) var(--spacer-1, 0.25rem) var(--spacer-4, 1.5rem);
      margin: var(--spacer-2, 0.5rem) var(--spacer-0, 0rem) var(--spacer-2, 0.5rem) var(--spacer-3, 1rem);
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}


.between-modules-navigation {
  display: flex;
  width: auto;
  padding: var(--spacer-3, 1rem) var(--spacer-1, 0.25rem);
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid var(--gray-color-variables-gray-200, #E9ECEF);

  margin: 1.75rem 0 4.87rem;

  @include media-breakpoint-up(md) {
    justify-content: space-between;
    padding: var(--spacer-3, 1rem);

    margin-top: 4rem;
    margin-bottom: 0;
  }

  a {
    display: flex;
    min-height: 2.5rem;
    padding: 0.625rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    font-family: $display-font-family;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem; /* 142.857% */
    letter-spacing: -0.01563rem;
    text-decoration: none;

    svg {

    }
  }
}


.module-content-frame{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 0;
  padding-right: 0;

  @include media-breakpoint-up(md) {
    width: auto;
  }

  .illustration-card-wrapper {
    margin-right: 0;
    margin-left: 0;
  }
}

.module-content-frame:not(.paragraph-extension) {
  @media (min-width: 768px) {
    overflow-y: scroll;
    height: calc(100vh - 64px);
  }
}

//////////////////////////////////////////////////

.container-single-centered-item {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
  height: calc(100vh - 62px);

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 52px);
  }
}

.container-single-v-centered-item {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
  height: calc(100vh - 62px);

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: center;
    height: calc(100vh - 52px);
  }
}

.text-brand {
  color: $brand;
}

.btn-brand {
  color: $brand-800;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  white-space: nowrap;
  min-height: 3rem;
  padding: 0.75rem 1rem;

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: -0.01563rem;

  @include media-breakpoint-up(md) {
    font-size: 1rem;
    line-height: 150%; /* 1.5rem */
  }

  &:active {
    transform: translateY(1px);
  }
}

.btn-primary {
  box-shadow: 4px 4px 0px 0px #FDB572;

  &:hover {
    box-shadow: 2px 2px 0px 0px #FDB572;
  }
}

.btn-secondary {
  border-radius: 0.375rem;
  border-color: $primary;
  background: $white;

  /* Button/small */
  box-shadow: 4px 4px 0px 0px #FDB572;

  color: $gray-900;
  font-family: $display-font-family;

  &:hover {
    background: $white;
    box-shadow: 2px 2px 0px 0px #FDB572;
    color: $gray-900;
  }
  &:first-child:active, &:active {
    background: $white!important;
    color: $gray-900!important;
  }

  &:disabled {
    background: $white;
    color: $gray-500;
    border: 2px solid $gray-300;
  }

}

.btn-light {
  background: $white;
  border: none;
  box-shadow: none;

  &:hover, &:active {
    background: $white;
  }
  &:first-child:active, &:active {
    background: $white!important;
    color: $gray-900!important;
    box-shadow: none;
  }
}

.btn-sm {
  padding: 0.625rem 0.75rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: -0.01563rem;
  font-size: 0.875rem;
  border: unset;
  min-width: 5rem;
  min-height: 2.5rem;
}


.input-group-stacked {
  position: relative;
  width: 100%;

  input[type="text"] {
    padding-right: 90px;
    border-radius: $border-radius!important;
    margin-left: 0!important;
    box-shadow: unset;
  }

  &>.form-control:focus {
    z-index: unset;
  }

  .btn {
    border: none;
    box-shadow: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: auto!important;
  }
}

.input-group > .form-control {
  border-right: unset;
}

.input-group-text {
  background-color: $white;
  color: $body-secondary-color;
  border-left: unset;
}

.input-group > .form-control:focus + .input-group-text {
  border-color: $brand;
}

.offcanvas {
  background-color: $white;
}

.nav.tabs {
  gap: var(--spacer-3, 1rem);


  .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;

    a {
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 1.5rem */
      letter-spacing: -0.03rem;
      color: $gray-600;
      padding: 0.5rem 0.25rem;

      &.active {
        border-bottom: 2px solid $brand-500;
      }

      &.active, &:hover{
        color: $gray-900;
      }
    }
  }
}

.accordion {
  .accordion-item {
    border-radius: 0.5rem;
    border: 2px solid var(----bs-primary, #212529);
    box-shadow: 4px 4px 0px 0px #FDB572;
    padding: 0.1rem;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 1.25rem;
      padding: 1.25rem 1.5rem;
    }

    .accordion-button {
      font-weight: 600;
      gap: 1rem;

      @include media-breakpoint-up(md) {
        padding: 0;
      }
    }

    .accordion-body {
      @include media-breakpoint-up(md) {
        padding: 1rem 0;
      }
    }
  }
}


.modal-content {
  border-radius: var(--spacer-2, 0.5rem);
  background: $white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  border: unset;

  .modal-header {
    padding: var(--spacer-4, 1.5rem);
    padding-right: 2rem;
    align-items: center;

    @include media-breakpoint-up(md) {
      padding: var(--spacer-5, 3rem) var(--spacer-5, 3rem) var(--spacer-4, 1.5rem) var(--spacer-5, 3rem);
    }

    .btn-close {
      position: absolute;
      right: 1.25rem;
      top: 1.25rem;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: var(--spacer-0, 0rem) var(--spacer-4, 1.5rem);
    gap: var(--spacer-3, 1rem);

    @include media-breakpoint-up(md) {
      padding: var(--spacer-0, 0rem) var(--spacer-5, 3rem);
    }
  }
  .modal-footer{
    min-height: var(--spacer-5, 3rem);
    padding: var(--spacer-4, 1.5rem) var(--spacer-5, 3rem) var(--spacer-5, 3rem) var(--spacer-5, 3rem);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
  }
}

.navbar {

  padding: var(--spacer-2, 0.5rem) var(--spacer-3, 1rem) var(--spacer-2, 0.5rem) var(--spacer-1, 1rem);

  @include media-breakpoint-up(md) {
    padding: 0.5rem 1.5rem;
  }

  img.logo {
    height: 1.25rem;
  }

  .navbar-brand {
    color: var(--Body-Text-Body-Color, #1E1A1C);
    font-family: $display-font-family;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.01563rem;
    font-size: 0.875rem;
    line-height: 1rem; /* 142.857% */

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-right: 0;

    @include media-breakpoint-up(md) {
      font-size: 1rem;
      padding: 0;
    }

  }

  .navbar-nav {
    display: flex;
    padding: var(--spacer-0, 0rem);
    justify-content: flex-end;
    flex-direction: row;

    align-items: center;
    gap: var(--spacer-2, 0.5rem);
    flex: 1 0 0;

    @include media-breakpoint-up(md) {
      gap: var(--spacer-3, 1rem);
    }

    .nav-link.nav-link-get-credits {
      display: flex;
      min-height: 2.5rem;
      padding: 0.625rem 0.75rem;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      border-radius: 0.5rem;

      color: var(--Color-text-body, var(--gray-color-variables-gray-900, #212529));
      font-family: $display-font-family;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -0.01563rem;
      font-size: 0.875rem;
      line-height: 1.25rem; /* 142.857% */

      @include media-breakpoint-up(md) {
        min-height: 3rem;
        padding: 0.75rem 1rem;
        font-size: 1rem;
        line-height: 150%; /* 1.5rem */
      }
    }

  }

}

.navbar-main{
  box-shadow: 0px 2px 4px 0px #5C472713;
  background: #ffffff;
}

.navbar-landing {
  padding: var(--spacer-3, 1rem);

  @include media-breakpoint-up(md) {
    padding: var(--spacer-3, 1rem) var(--spacer-4, 1.5rem);
  }
}

.card {
  border: none;
  background: $white;

  @include media-breakpoint-up(md) {
    box-shadow: 0px 2px 4px 0px rgba(92, 71, 39, 0.08);
  }
}

.card.card-course {
  padding: var(--spacer-3, 1rem);
  border-radius: var(--rounded-2, 0.5rem);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);

  a {
    text-decoration: none;
  }

  .card-body{
    display: flex;
    align-items: flex-start;
    gap: var(--spacer-3, 1rem);
    padding: 0;

    h4 {
      margin-bottom: 0;
    }
  }

}

.card.card-large {
  padding: var(--spacer-4, 1.5rem) var(--spacer-3, 1rem);
  flex-direction: column;
  gap: var(--spacer-4, 1.5rem);
  border-radius: 0;

  @media (min-width: 768px) {
    border-radius: var(--spacer-3, 1rem);
    padding: var(--spacer-5, 3rem);
  }

  .card-body{
    padding: 0;
    flex-direction: column;
    gap: var(--spacer-4, 1.5rem);
    display: flex;
  }
}

.container-single-centered-item .card-large {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 26.5rem;
  }
}

.card.card-lesson {
  border-radius: var(--rounded-2, 0.5rem);
  padding: var(--spacer-4, 1.5rem) var(--spacer-3, 1rem);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid $gray-200;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    padding: var(--spacer-4, 1.5rem);
  }

  .card-header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background: none;
    border: none;
    padding: 0;
  }
}

// FIXME: merge with card-large

.book-icon{
  color: $brand;
  padding: 0.75rem;
  align-items: flex-start;
  border-radius: 0.5rem;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), var(--brand-brand-100, #FCE3CC);

  svg {
    width: 2rem;
    height: 2rem;
  }
}

.offcanvas-brand {
  display: flex;
  min-height: 2.5rem;
  padding: 0.625rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  font-family: $display-font-family;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: -0.01563rem;
  text-decoration: none;
}


.modules-menu {
  flex-direction: column;
  align-items: flex-start;
  padding: var(--spacer-2, 0.5rem);
  gap: var(--spacer-2, 0.5rem);

  @media (min-width: 768px) {
    padding: var(--spacer-4, 1.5rem) var(--spacer-2, 0.5rem) var(--spacer-4, 1.5rem) var(--spacer-0, 0rem);
    margin-left: 0.5rem;
    overflow-y: scroll;
    height: calc(100vh - 64px);
    border-right: 1px solid var(--gray-color-variables-gray-200, #E9ECEF);
    min-width: 21.125rem;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    turbo-frame {
      width: 100%;
    }
  }

  .nav-item.active {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), var(--brand-100, #FCE3CC);
    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), var(--brand-100, #FCE3CC);
    }
  }
  .nav-item:hover {
    background: var(--gray-color-variables-gray-100, #F8F9FA);
  }

  .nav {
    width: 100%;
    max-width: 100%;
  }

  .nav-item {
    display: flex;
    padding: var(--spacer-2, 0.5rem) var(--spacer-3, 1rem);
    justify-content: space-between;
    align-items: center;
    gap: var(--spacer-2, 0.5rem);
    border-radius: 0.25rem;
    position: relative;

    width: 100%;
    max-width: 100%;

    .nav-link {
      padding: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      //max-width: 15.7rem;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 1.3125rem */
    }
  }

  .nested-nav {
    .nav-item{
      padding: var(--spacer-2, 0.5rem) var(--spacer-3, 1rem) var(--spacer-2, 0.5rem) 2rem;

      .nav-link {
        font-weight: 400;
      }
    }
  }
}

// Deleted on 2024-03-21
//.btn-open-course-plan{
//  border-radius: var(--rounded-2, 0.5rem);
//  border: 2px solid $primary;
//  background: $white;
//  /* shadow-lg */
//  box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.18);
//  z-index: 2;
//
//  display: flex;
//  padding: 0.625rem 0.75rem;
//  flex-direction: row;
//
//  min-height: 2.5rem;
//  justify-content: center;
//  align-items: center;
//  gap: 0.5rem;
//
//
//  &:active, &:hover, &:focus-visible {
//    background: $white!important;
//    border-color: $primary;
//  }
//}

.alert {
  margin: var(--spacer-3, 1rem) 0rem;
  border-radius: var(--rounded-2, 0.5rem);
  display: flex;
  gap: var(--spacer-2, 0.5rem);
  align-items: center;
  font-size: 0.875rem;
}

.alert-info {
  border: 2px solid var(--brand-brand-500, #EE7200);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), var(--brand-brand-100, #FCE3CC);
  box-shadow: 4px 4px 0px 0px rgba(238, 114, 0, 0.16);
}

.dropdown-menu {

  padding: 0.5rem var(--spacer-0, 0rem);

  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacer-0, 0rem);

  align-self: stretch;

  border-radius: var(--rounded-2, 0.5rem);
  border: var(--spacer-0, 1px) solid $gray-300;

  background: #FFF;

  &.show {
    display: flex;
  }

  li {
    width: 100%;

    .btn {
      display: flex;
      padding: 0.5rem 1rem;

      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;

      align-self: stretch;

      border: none;
      box-shadow: none;
      font-weight: 400;
      font-family: $font-family-base;

      &:hover {
        background-color: $gray-100;
      }
    }

    .close {
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 1.3125rem */
    }
  }
}

.module-feedback-action-form {
  display: flex;
  padding: var(--spacer-4, 1.5rem) var(--spacer-3, 1rem);
  margin-top: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacer-3, 1rem);
  align-self: stretch;
  border-radius: 0.5rem;
  border: 1px solid $gray-200;
  background: $white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);

  @include media-breakpoint-up(md){
    margin-top: 3rem;
  }

  form {
    width: 100%;

    .btn-secondary {
      width: 100%;
      justify-content: center;

      @include media-breakpoint-up(md) {
        width: unset;
        justify-content: start;
      }
    }
  }

  .presets {
    display: flex;
    padding: var(--spacer-0, 0rem);

    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacer-3, 1rem);
    align-self: stretch;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: var(--spacer-4, 1.5rem);
    }

    form {
      @include media-breakpoint-up(md) {
          width: unset;
      }
    }
  }

}

turbo-frame#generate-form {
  display: flex;
  flex-direction: column;
  gap: var(--spacer-4, 1.5rem);

  form {
    display: flex;
    padding: var(--spacer-5, 3rem) var(--spacer-4, 1.5rem);
    flex-direction: column;
    align-items: center;
    gap: var(--spacer-4, 1.5rem);
    align-self: stretch;

    border-radius: var(--rounded-2, 0.5rem);
    border: 1px solid $gray-200;
    background: $white;

    /* shadow-sm */
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);

    p {
      text-align: center;
      margin-bottom: 0;
    }

    button {
      width: 100%;
      justify-content: center;
    }
  }
}

//////////////////////////////////////////////////


.credit-pack-list{
  display: flex;
  padding: var(--spacer-0, 0rem);
  flex-direction: column;

  .credit-pack:not(:last-child) {
    border-bottom: 1px solid $gray-300;
  }
}

.landing{
  display: flex;
  flex-direction: column;
  padding-right: 0;
  padding-left: 0;
  font-size: 0.875rem;
  gap: 1.25rem;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
    gap: 0rem;
  }

  h4, p {
    margin-bottom: 0;
  }

  .hero{
    display: flex;
    padding: var(--spacer-5, 3rem) var(--spacer-3, 1rem);
    flex-direction: column;
    align-items: center;
    gap: var(--spacer-5, 3rem);
    margin-top: 1.5rem;

    @include media-breakpoint-up(lg) {
      margin: 4rem auto;
      padding: 3.5rem 0rem;
      max-width: 67.25rem;
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--spacer-2, 0.5rem);
      text-align: center;
      font-size: 1rem;

      p {
        line-height: 150%; /* 1.5rem */
      }

      h2 {
        margin-top: 0rem;
      }

      @include media-breakpoint-up(lg) {
        padding: 0 13rem;
        gap: var(--spacer-3, 1rem);
      }
    }

    .form{
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      gap: var(--spacer-3, 1rem);
      width: 100%;

      @include media-breakpoint-up(lg) {
        padding: var(--spacer-0, 0rem) 5rem;
        max-width: 53.5rem;
        flex-direction: row;
        align-items: flex-end;
      }

      button {
        width: 100%;

        @include media-breakpoint-up(lg) {
          width: unset;
        }
      }
    }
  }

  u {
    text-decoration-color: $brand-500;
    text-decoration-thickness: 0.25rem;
    text-underline-offset: 0.375rem;
    text-decoration-skip-ink: none;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include media-breakpoint-up(lg) {
      gap: 1.5rem;
    }

    .section {
      display: flex;
      padding: var(--spacer-5, 3rem) var(--spacer-4, 1.5rem);
      flex-direction: column;
      align-items: center;
      gap: var(--spacer-4, 1.5rem);
      border: 4px solid $bg-brand-tertiary;
      background: $bg-brand-tertiary;

      @include media-breakpoint-up(lg) {
        padding: 5rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        border-radius: var(--rounded-2, 0.5rem);
        overflow: hidden;
      }

      &.section-screenshot {
        @include media-breakpoint-up(lg) {
          //padding-bottom: 0;
          padding-right: 0;
        }
      }

      &.section-discover-magic-wand {
        @include media-breakpoint-up(lg) {
          flex-direction: column;
          align-items: center;
          gap: 3rem;
          text-align: center;
          padding: 5rem;
          .section-text {
            align-items: center;
            text-align: center;
            max-width: 37.5rem;
          }
        }
      }

      &.section-faq {
        @include media-breakpoint-up(lg) {
          flex-direction: column;
          align-items: center;

          .section-text{
            align-items: center;
          }

          .accordion {
            @include media-breakpoint-up(lg) {
              width: 40rem;
            }
          }
          .accordion-body {
            max-width: 40rem;
          }
        }
      }

      .section-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        color: $brand-800;
        text-align: center;

        @include media-breakpoint-up(lg) {
          align-items: flex-start;
          gap: 1.5rem;
          text-align: left;
          max-width: 22.75rem;
        }

        svg.section-icon {
          display: none;
          @include media-breakpoint-up(lg) {
            display: block;
          }
        }
      }

      .examples {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @include media-breakpoint-up(lg) {
          flex-direction: row;
          gap: 1.5rem;
          max-width: 36rem;
        }

        .examples-column {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          @include media-breakpoint-up(lg) {
            gap: 1.5rem;
            width: 50%;
            &:last-child {
              padding-top: 1.25rem;
            }
          }
        }

        .example{
          display: flex;
          padding: 1rem;
          justify-content: center;
          align-items: center;
          gap: 0.625rem;
          border-radius: 0.5rem;
          border: 2px solid $primary;
          background: $white;
          box-shadow: 4px 4px 0px 0px #FDB572;
          color: $gray-900;
          text-align: center;

          @include media-breakpoint-up(lg) {
            padding: var(--rounded-4, 2rem) 2.5rem;
          }
        }
      }

      .levels {
        display: flex;
        justify-content: center;
        gap: 0.75rem;

        @include media-breakpoint-up(lg) {
          flex-direction: column;
          align-items: flex-start;
        }

        .level {
          display: flex;
          align-items: center;
          gap: 0.25rem;
          color: $brand-800;
        }

        &.levels-long {
          flex-direction: column;
        }
      }

      .learning-steps{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        @include media-breakpoint-up(lg) {
          flex-direction: row;
          gap: 2rem;
        }

        .learning-step{
          display: flex;
          padding: 1rem;
          align-items: center;
          gap: 0.75rem;
          align-self: stretch;
          border-radius: 0.5rem;
          border: 2px solid $primary;
          background: $white;
          box-shadow: 4px 4px 0px 0px #FDB572;
          @include media-breakpoint-up(lg) {
            padding: 1.5rem 2rem;
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
            flex: 1 1 0;
            text-align: left;
          }
        }
      }
    }

    .get-started {
      display: flex;
      padding: var(--spacer-5, 3rem) var(--spacer-4, 1.5rem) var(--spacer-4, 1.5rem) var(--spacer-4, 1.5rem);
      flex-direction: column;
      align-items: center;
      gap: var(--spacer-5, 3rem);

      @include media-breakpoint-up(lg) {
        padding: 5rem var(--spacer-5, 3rem);
        border-radius: 0.5rem;
        border: 1px solid var(--gray-color-variables-gray-200, #E9ECEF);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
      }

      .text {
        gap: var(--spacer-2, 0.5rem);
        text-align: center;
        align-items: center;

        @include media-breakpoint-up(lg) {
          gap: var(--spacer-4, 1.5rem);
        }
      }

      .form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--spacer-3, 1rem);
        max-width: 39.75rem;
      }
    }
  }

  .landing-footer {
    display: flex;
    padding: var(--spacer-4, 1.5rem) var(--spacer-0, 0rem);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacer-2, 0.5rem);
    color: $gray-600;
    font-size: 0.875rem;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      gap: 1rem;
    }


    .text-muted{
      opacity: 0.5;
    }

    a {
      color: $gray-600;
    }
  }

  &.course-landing{
    .modules-menu{
      text-align: left;
      height: auto;
      border: 2px solid $gray-900;
      border-radius: 0.5rem;
      background: #FFF;
      box-shadow: 4px 4px 0 0 #FDB572;
      padding: var(--spacer-4, 1.5rem) var(--spacer-2, 0.5rem) var(--spacer-4, 1.5rem) var(--spacer-0, 0rem);

      @include media-breakpoint-down(md) {
        width: calc(100% + 3rem);
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}

.course-creation-card {
  height: 100%;
  .card-body {
    justify-content: center;
  }
  @include media-breakpoint-up(md) {
    min-width: 39.75rem;
  }
}

.offcanvas-modules-menu {
  padding: 0;

  .offcanvas-header {
    padding: var(--spacer-2, 0.5rem) var(--spacer-3, 1rem) var(--spacer-2, 0.5rem) var(--spacer-1, 0.25rem);
  }
}

.text {
  h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  h3 {
    margin-top: 1.5rem;
    margin-bottom: .5rem;
  }

}

hr {
  border-color: $gray-200;
  opacity: 1;
  width: 100%;
}

.quote {
  display: flex;
  padding: var(--spacer-3, 1rem) var(--spacer-3, 1rem) var(--spacer-3, 1rem) var(--spacer-2, 0.5rem);
  gap: var(--spacer-3, 1rem);

  svg {
    width: 1.5rem;
    height: 1.5rem;

    @include media-breakpoint-up(md) {
      width: 2rem;
      height: 2rem;
    }
  }

  .quote-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacer-2, 0.5rem);

    .quote-text {
      font-family: "Bitter", serif;
      font-size: 1rem;
      font-weight: 500;
      line-height: 140%;

      @include media-breakpoint-up(md) {
        font-size: 1.25rem;
      }
    }

    .quote-author {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 150%; /* 1.3125rem */
    }
  }
}

.related-videos {
  display: flex;
  flex-direction: column;
  gap: var(--spacer-3, 1rem);

  @include media-breakpoint-up(md) {
    gap: var(--spacer-4, 1.5rem);
  }

  h2 {
    margin-top: 0!important;
    margin-bottom: 0!important;
  }

  .related-videos-list {
    display: flex;
    flex-direction: column;
    gap: var(--spacer-3, 1rem);
    width: 100%;

    @include media-breakpoint-up(md) {
      gap: var(--spacer-4, 1.5rem);
      flex-direction: row;
    }

    iframe {
      border-radius: var(--rounded-2, 0.5rem);
      border: 2px solid var(----bs-primary, $primary);
      aspect-ratio: 16/9;
      box-shadow: 4px 4px 0px 0px #FDB572;
    }

    .col-lg-6 {
      @include media-breakpoint-up(md) {
        width: 47%;
      }
    }
  }

  .related-videos-load-more .btn {
    display: flex;
    min-height: 3rem;
    padding: 0.75rem 1rem;
    justify-content: center;
    align-items: center;
    border: unset;
    box-shadow: none;

    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 1.5rem */
    letter-spacing: -0.01563rem;
  }
}

.landing.text {
  p {
    margin-bottom: 1rem;
  }
}

.placeholder{
  border-radius: 0.25rem;
}

@import "../../../blog/static/blog.scss";

.lesson-text {
  h1 {
    margin-bottom: 2rem;
  }

  h2 {
    margin-bottom: 1rem;

    &:not(:first-child) {
      margin-top: 2rem;
    }
  }

  h3 {
    margin-top: 1.5rem;
    margin-bottom: .5rem;
  }

  li p {
    margin-bottom: 0;
  }

  pre:has(code.language-mermaid) {
    text-align: center;
  }

  code.language-mermaid svg {
    height: 300px;
  }
}


// This is very dirty, but I'm in a hurry
.max-width-8rem{
    max-width: 8rem;
}
.max-width-9rem{
    max-width: 9rem;
}
