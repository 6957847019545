.article-preview {
  padding: var(--spacer-0, 0rem) var(--spacer-3, 1rem);

  padding-bottom: 3rem;

  position: relative;

  @include media-breakpoint-up(md) {
    padding-right: 2.5rem;
  }

  .article-thumbnail{
    border-radius: var(--rounded-2, 0.5rem);
    border: 2px solid $primary;
    /* Button/default */
    box-shadow: 4px 4px 0px 0px #FDB572;
  }

  a {
    text-decoration: none;
  }

  .article-intro {
    padding-right: var(--spacer-4, 1.5rem);
    flex-direction: column;
    gap: 1rem;
  }
}

.article-details {
  margin: 4rem auto;

  .article-header{
    h2 {
      margin: 0;
    }
  }

  .article-body{
    padding-top: var(--spacer-5, 3rem);
    //max-width: 636px;

  }
}
